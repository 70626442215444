import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import ServiceSlider from '../components/services/servicesSlider'
import TeamSlider from '../components/team/teamSlider'
import * as G from '../styles/globalStyle'
import * as S from '../styles/pages/homeStyle'
import ContactInfo from '../components/contactInfo/contactInfo'
import * as Shape1 from '../assets/images/static/shape1.svg'
import * as Shape2 from '../assets/images/static/shape2.svg'
import * as Shape3 from '../assets/images/static/shape3.svg'

export default function Home({ data }) {
  return (
    <Layout>
      <S.HeroSection>
        <S.HeroShape1>
          <Shape1 />
        </S.HeroShape1>
        <S.HeroContainer>
          <S.HeroLeftContainer>
            <S.HeroHeader>Salon Evita</S.HeroHeader>
            <S.HeroText>
              Salon Kosmetyki Profesjonalnej „Evita” został założony w roku 2010
              przez Ewę Plekaniec, kosmetyczkę z 28-letnim doświadczeniem w
              branży kosmetologicznej. Obecnie za jego sukcesem stoi pięć
              wykwalifikowanych pasjonatek. Jest to miejsce wyróżniające się
              indywidualnym, ciepłym podejściem do klienta, wysokim stopniem
              profesjonalizmu i niegasnącą pasją. Klienci cenią sobie naszą
              opiekę, darzą nas zaufaniem i chętnie do nas wracają, ponieważ ich
              satysfakcja i zadowolenie z rozwiązanych problemów jest naszym
              priorytetem.
            </S.HeroText>
            <G.LinkButton to="/o-nas">dowiedz się więcej</G.LinkButton>
          </S.HeroLeftContainer>
          <S.HeroImg
            fluid={data.heroImg.fluid}
            alt="Salon kosmetyki profesjonalnej Evita."
            height="600"
            critical
          />
        </S.HeroContainer>
      </S.HeroSection>
      <S.ServicesSection>
        <S.ServicesShape1>
          <Shape2 />
        </S.ServicesShape1>
        <S.ServicesShape2>
          <Shape2 />
        </S.ServicesShape2>
        <G.Container>
          <G.SubHeader>Zabiegi</G.SubHeader>
          <ServiceSlider
            data={data.servicesList.edges}
            images={data.servicesImages.edges}
          ></ServiceSlider>
        </G.Container>
      </S.ServicesSection>
      <S.TeamSection>
        <S.TeamShape1>
          <Shape3 />
        </S.TeamShape1>
        <G.Container>
          <G.SubHeader>Nasi Pracownicy</G.SubHeader>
          <TeamSlider
            data={data.team.edges}
            images={data.teamImages.edges}
          ></TeamSlider>
        </G.Container>
      </S.TeamSection>
      <ContactInfo />
    </Layout>
  )
}

export const query = graphql`
  query HomeQuery {
    team: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "markdowns/team/.*.md/" } }
    ) {
      edges {
        node {
          frontmatter {
            name
            id
            photoAlt
            text
            order
          }
        }
      }
    }
    teamImages: allFile(
      filter: { absolutePath: { regex: "assets/images/team/.*.jpg/" } }
    ) {
      edges {
        node {
          name
          absolutePath
          publicURL
        }
      }
    }
    heroImg: imageSharp(
      fluid: {
        originalName: { eq: "salon_kosmetyki_profesjonalnej_evita_25.jpg" }
      }
    ) {
      fluid(maxWidth: 970, quality: 100) {
        src
      }
    }
    servicesList: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "markdowns/zabiegi/list/.*.md/" } }
    ) {
      edges {
        node {
          frontmatter {
            name
            text
            id
            link
          }
        }
      }
    }
    servicesImages: allFile(
      filter: { absolutePath: { regex: "assets/images/services/.*.jpg/" } }
    ) {
      edges {
        node {
          name
          absolutePath
          publicURL
        }
      }
    }
  }
`
