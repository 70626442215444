import React from 'react'
import * as S from '../../styles/components/list/listStyle'

export default function listItem(props) {
  let listItem
  if (props.link !== undefined) {
    listItem = (
      <S.UnorderedListItem icon={props.icon}>
        <S.UnorderedListBullet>{props.children}</S.UnorderedListBullet>
        <S.ListLink href={props.link} target="_blank">
          {props.text}
        </S.ListLink>
      </S.UnorderedListItem>
    )
  } else {
    listItem = (
      <S.UnorderedListItem icon={props.icon}>
        <S.UnorderedListBullet>{props.children}</S.UnorderedListBullet>
        <S.ListText>{props.text}</S.ListText>
      </S.UnorderedListItem>
    )
  }
  return <>{listItem}</>
}
