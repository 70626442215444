import styled from 'styled-components'
import { MainText, LinkButton } from '../../globalStyle'
import { ArrowIosDownward } from '@styled-icons/evaicons-solid/ArrowIosDownward'

export const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  max-width: 350px;
  height: 390px;
  background-color: ${({ theme }) => theme.color.fourth};
  overflow: hidden;
  position: relative;
  padding: 17px;
  padding-top: 220px;
  margin: auto;
  border-radius: 7px;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: grab;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
  }
`

export const ServicesIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  max-width: 350px;
  height: 390px;
  background-color: ${({ theme }) => theme.color.fourth};
  overflow: hidden;
  position: relative;
  padding: 17px;
  padding-top: 220px;
  margin: 10px;
  border-radius: 7px;
`

export const SlideImageContainer = styled.div`
  height: 360px;
  width: 360px;
  border-radius: 50%;
  position: absolute;
  overflow: hidden;
  top: -160px;
  left: -45px;
`

export const SlideImage = styled.img`
  height: auto;
  width: 325px;
  position: absolute;
  bottom: 0;
  right: 0;
  object-fit: contain;
`

export const SlideTitle = styled.h5`
  font-family: 'Book Antiqua';
  font-size: 30px;
  text-align: center;
  color: ${({ theme }) => theme.color.secondary};
  margin: auto;
`

export const SlideText = styled(MainText)`
  text-align: center;
`

export const SlideLink = styled(LinkButton)`
  font-size: 16px;
  margin-bottom: 3px;
`

export const SliderContainer = styled.div`
  width: 100%;
  margin: auto;
  margin-top: 35px;
  max-width: 1275px;

  @media (min-width: ${props => props.theme.size.small}) {
    width: calc(100% - 160px);
  }

  @media (min-width: ${props => props.theme.size.large}) {
    margin-top: 100px;
  }
`

export const ListItem = styled.li`
  margin-bottom: 35px;
`

export const ListItemTitleContainer = styled.div`
  width: 100%;
  height: 65px;
  padding: 0 50px 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.color.fourth};
  cursor: pointer;
  position: relative;
  border-radius: 9px;

  @media (min-width: ${props => props.theme.size.medium}) {
    padding: 0 50px;
  }
`

export const ListItemTextContainer = styled.div`
  padding: 0 20px;
  background-color: ${({ theme }) => theme.color.fifth};
  overflow-y: hidden;
  width: 100%;
  transition: max-height 0.4s;
  height: auto;
  max-height: ${props => props.activeItemHeight};
  border-radius: 9px;

  @media (min-width: ${props => props.theme.size.medium}) {
    padding: 0 100px;
  }
`

export const ListItemTitle = styled.h3`
  font-size: 18px;
  color: ${({ theme }) => theme.color.secondary};

  @media (min-width: ${props => props.theme.size.medium}) {
    font-size: 20px;
  }
`

export const ListItemText = styled.div`
  margin-top: 30px;

  @media (min-width: ${props => props.theme.size.medium}) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  p {
    margin-bottom: 25px;
    font-family: 'Book Antiqua';
    font-size: 14px;
    color: ${({ theme }) => theme.color.secondary};

    @media (min-width: ${props => props.theme.size.large}) {
      font-size: 16px;
    }
  }

  ul,
  ol {
    margin-bottom: 25px;
    font-family: 'Book Antiqua';
    font-size: 14px;
    color: ${({ theme }) => theme.color.secondary};
    padding-left: 30px;

    @media (min-width: ${props => props.theme.size.large}) {
      font-size: 16px;
    }
  }

  ul {
    list-style-type: none;

    li {
      position: relative;

      :before {
        content: '';
        position: absolute;
        display: block;
        height: 7px;
        width: 7px;
        top: 7px;
        left: -15px;
        background: ${({ theme }) => theme.color.secondary};
        border-radius: 50%;
      }
    }
  }

  h6 {
    margin-bottom: 15px;
    font-family: 'Book Antiqua';
    font-size: 16px;
    color: ${({ theme }) => theme.color.secondary};

    @media (min-width: ${props => props.theme.size.large}) {
      font-size: 18px;
    }
  }
`

export const ListBody = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

export const Arrow = styled(ArrowIosDownward)`
  color: white;
  font-size: 20px;
  height: 30px;
  position: absolute;
  right: 30px;
  transition: transform 0.4s;
  transform: ${props => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`
