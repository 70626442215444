import React, { useRef, useEffect } from 'react'
import * as S from '../../styles/components/mapItem/mapItemStyle'
import * as G from '../../styles/globalStyle'
import L from 'leaflet'

export default function MapItem() {
  const mapRef = useRef(null)

  useEffect(() => {
    var map = L.map(mapRef.current).setView(
      [49.682294262202916, 19.20841112568376],
      18
    )

    L.tileLayer(
      'https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=zXJo2dtb2xmz0hHZ6BlT',
      {
        attribution:
          '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
        maxZoom: 22,
      }
    ).addTo(map)

    const icon = L.icon({
      iconUrl: '../logo-mapa-mini.png',

      iconSize: [51, 100],
      iconAnchor: [25, 100],
    })

    L.marker([49.682294262202916, 19.20841112568376], { icon: icon }).addTo(map)
  }, [])

  return <S.MapMainDiv id="map" ref={mapRef}></S.MapMainDiv>
}
