import styled from 'styled-components'
import { Mail } from '@styled-icons/feather/Mail'
import { Address } from '@styled-icons/entypo/Address'
import { Telephone } from '@styled-icons/bootstrap/Telephone'
import { Facebook } from '@styled-icons/boxicons-logos/Facebook'
import { Time } from '@styled-icons/ionicons-outline/Time'

export const ContactInfoSection = styled.section`
  padding-top: 45px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${props => props.theme.size.large}) {
    flex-direction: row;
  }
`

export const ContactInfoContainer = styled.div`
  @media (min-width: ${props => props.theme.size.small}) {
    width: 100%;
  }

  @media (min-width: ${props => props.theme.size.large}) {
    width: 45%;
  }
`

export const ContactInfoGmapsContainer = styled.div`
  width: 100%;
  height: 430px;
  max-height: 80vh;
  position: relative;
  overflow: hidden;
  border-radius: 9px;

  @media (min-width: ${props => props.theme.size.medium}) {
    height: 550px;
  }

  @media (min-width: ${props => props.theme.size.large}) {
    width: 55%;
    height: 670px;
    max-height: unset;
  }
`

export const ContactInfoMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;

  @media (min-width: ${props => props.theme.size.large}) {
    flex-direction: row;
    align-items: center;
    margin-top: 90px;
  }
`

export const MailIcon = styled(Mail)`
  color: white;
  height: 22px;
  display: block;
`
export const AddressIcon = styled(Address)`
  color: white;
  height: 22px;
  display: block;
`
export const TelephoneIcon = styled(Telephone)`
  color: white;
  height: 20px;
  display: block;
`
export const FacebookIcon = styled(Facebook)`
  color: white;
  height: 26px;
  display: block;
`
export const TimeIcon = styled(Time)`
  color: white;
  height: 24px;
  display: block;
`
