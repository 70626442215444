import styled from 'styled-components'
import { MainText } from '../../globalStyle'

export const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  max-width: 350px;
  height: 423px;
  background-color: ${({ theme }) => theme.color.fourth};
  overflow: hidden;
  position: relative;
  padding: 17px;
  padding-top: 30px;
  margin: auto;
  border-radius: 7px;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
  }
`

export const TeamIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100%);
  background-color: ${({ theme }) => theme.color.fourth};
  position: relative;
  padding: 30px;
  margin: 10px 10px;
  border-radius: 7px;
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
  }

  @media (min-width: ${props => props.theme.size.large}) {
    width: calc(50% - 20px);
  }
`

export const SlideImage = styled.img`
  height: 170px;
  width: 170px;
  object-fit: cover;
  object-position: 50% 18%;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
`

export const SlideTitle = styled.h5`
  font-family: 'Book Antiqua';
  font-size: 30px;
  text-align: center;
  color: ${({ theme }) => theme.color.secondary};
  margin-bottom: 12px;
`

export const SlideText = styled(MainText)`
  text-align: center;
`

export const TeamIconText = styled(MainText)`
  text-align: justify;
`

export const SliderContainer = styled.div`
  width: 100%;
  margin: auto;
  margin-top: 35px;
  max-width: 1275px;

  @media (min-width: ${props => props.theme.size.small}) {
    width: calc(100% - 160px);
  }

  @media (min-width: ${props => props.theme.size.large}) {
    margin-top: 100px;
  }
`
