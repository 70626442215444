import React from 'react'
import Slide from './teamSlide'
import Slider from 'react-slick'
import * as S from '../../styles/components/team/teamStyle'

export default function TeamSlider(props) {
  const imagesMap = new Map()
  props.images.forEach(elem => {
    imagesMap.set(elem.node.name, elem.node.publicURL)
  })

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  }

  const slidesData = props.data.sort((a, b) => {
    return a.node.frontmatter.order - b.node.frontmatter.order
  })

  const slides = slidesData.map(elem => {
    return (
      <Slide
        key={elem.node.frontmatter.id}
        title={elem.node.frontmatter.name}
        text={elem.node.frontmatter.text}
        image={imagesMap.get(elem.node.frontmatter.id)}
        alt={elem.node.frontmatter.photoAlt}
      />
    )
  })
  return (
    <S.SliderContainer>
      <Slider {...sliderSettings}>{slides}</Slider>
    </S.SliderContainer>
  )
}
