import React from 'react'
import * as S from '../../styles/components/contactInfo/contactInfoStyle'
import * as G from '../../styles/globalStyle'
import List from '../list/unorderedList'
import ListItem from '../list/unorderedListItem'
import MapItem from '../mapItem/mapItem'

export default function ContactInfo() {
  return (
    <S.ContactInfoSection>
      <G.Container>
        <G.SubHeader>Kontakt</G.SubHeader>
        <S.ContactInfoMainContainer>
          <S.ContactInfoContainer>
            <List title="Dane kontaktowe:">
              <ListItem
                link="https://www.google.com/maps/place/Salon+Evita+Kosmetyka+Profesjonalna+Ewa+Plekaniec/@49.6822422,19.2062278,17z/data=!4m13!1m7!3m6!1s0x4714289c972efa5b:0x43fe4db74bb06c5!2saleja+Marsza%C5%82ka+J%C3%B3zefa+Pi%C5%82sudskiego+78a,+34-315+%C5%BBywiec!3b1!8m2!3d49.6822422!4d19.2084165!3m4!1s0x471429a5114420ad:0xa419523cafece952!8m2!3d49.6822422!4d19.2084165"
                text="al. Piłsudskiego 78A, 34-300 Żywiec"
              >
                <S.AddressIcon />
              </ListItem>
              <ListItem link="tel:+48791927511" text="+48 791 927 511">
                <S.TelephoneIcon />
              </ListItem>
              <ListItem
                link="mailto:salon.evita@wp.pl"
                text="salon.evita@wp.pl"
              >
                <S.MailIcon />
              </ListItem>
              <ListItem
                link="https://www.facebook.com/SalonEvitaKosmetykaProfesjonalna"
                text="facebook"
              >
                <S.FacebookIcon />
              </ListItem>
            </List>
            <List title="Godziny otwarcia:">
              <ListItem text="pon - pt:  8:00 - 20:00">
                <S.TimeIcon />
              </ListItem>
              <ListItem text="sobota:  8:00 - 14:00">
                <S.TimeIcon />
              </ListItem>
            </List>
          </S.ContactInfoContainer>
          <S.ContactInfoGmapsContainer>
            <MapItem />
          </S.ContactInfoGmapsContainer>
        </S.ContactInfoMainContainer>
      </G.Container>
    </S.ContactInfoSection>
  )
}
