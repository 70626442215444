import styled from 'styled-components'

export const UnorderedList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 55px;
`

export const UnorderedListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;

  :before {
    content: ${props => props.icon};
  }
`

export const UnorderedListBullet = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  min-width: 40px;
  background-color: ${({ theme }) => theme.color.sixth};
  border-radius: 50%;
  margin-right: 10px;

  @media (min-width: ${props => props.theme.size.small}) {
    margin-right: 30px;
  }
`

export const ListTitle = styled.h6`
  text-align: left;
  font-size: 18px;
  font-family: 'Book Antiqua';
  margin-bottom: 30px;
`

export const ListText = styled.p`
  font-family: 'Book Antiqua';
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.secondary};

  @media (min-width: ${props => props.theme.size.large}) {
    font-size: 16px;
  }
`

export const ListLink = styled.a`
  font-family: 'Book Antiqua';
  font-size: 14px;
  color: ${({ theme }) => theme.color.secondary};
  text-decoration: none;
  cursor: pointer;
  font-weight: 700;

  :visited {
    text-decoration: none;
    color: ${({ theme }) => theme.color.secondary};
  }

  @media (min-width: ${props => props.theme.size.large}) {
    font-size: 16px;
  }
`
