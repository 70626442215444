import styled from 'styled-components'
import * as G from '../globalStyle'
import Img from 'gatsby-image'
//Hero Section

export const HeroSection = styled.section`
  position: relative;
  width: 100%;
  padding-bottom: 50px;

  @media (min-width: ${props => props.theme.size.large}) {
    padding-bottom: 150px;
    padding-top: 115px;
  }
`

export const HeroContainer = styled(G.Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${props => props.theme.size.large}) {
    flex-direction: row;
    align-items: flex-start;
  }
`

export const HeroHeader = styled(G.MainHeader)`
  text-align: center;

  @media (min-width: ${props => props.theme.size.large}) {
    text-align: left;
  }
`

export const HeroText = styled(G.MainText)`
  margin-top: 32px;
  margin-bottom: 40px;
  max-width: 400px;
  text-align: justify;

  @media (min-width: ${props => props.theme.size.large}) {
    max-width: auto;
  }
`

export const HeroLeftContainer = styled.div`
  width: 100%;
  max-width: 630px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${props => props.theme.size.large}) {
    width: 40%;
    align-items: flex-start;
  }
`

export const HeroImg = styled(Img)`
  display: none !important;
  overflow: visible !important;
  position: static !important;
  max-width: 970px;
  border-radius: 3px;

  img {
    position: static !important;
  }

  @media (min-width: ${props => props.theme.size.large}) {
    width: 55%;
    display: block !important;
  }
`

//Services Section

export const ServicesSection = styled.section`
  padding-top: 45px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

export const TeamSection = styled.section`
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (min-width: ${props => props.theme.size.large}) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
`

// SHAPES

export const HeroShape1 = styled(G.Shape)`
  top: 5px;
  left: -38px;

  @media (min-width: ${props => props.theme.size.large}) {
    top: 100px;
    left: -29px;

    svg {
      transform: scale(1.5);
    }
  }
`

export const ServicesShape1 = styled(G.Shape)`
  top: 22%;
  left: -146px;
  transform: rotate(305deg);

  svg {
    transform: scale(0.6);

    @media (min-width: ${props => props.theme.size.medium}) {
      transform: scale(1);
    }
  }
`

export const ServicesShape2 = styled(G.Shape)`
  top: 82%;
  right: -194px;
  transform: rotate(101deg);

  svg {
    transform: scale(0.6);

    @media (min-width: ${props => props.theme.size.medium}) {
      transform: scale(1);
    }
  }
`

export const TeamShape1 = styled(G.Shape)`
  bottom: -141px;
  left: -136px;
  transform: rotate(-90deg);

  svg {
    transform: scale(0.4);

    @media (min-width: ${props => props.theme.size.medium}) {
      transform: scale(0.7);
    }
  }
`
