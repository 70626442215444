import React from 'react'
import * as S from '../../styles/components/services/servicesStyle'

export default function Slide(props) {
  return (
    <S.SlideContainer>
      <S.SlideImageContainer>
        <S.SlideImage src={props.image} alt="" />
      </S.SlideImageContainer>
      <S.SlideTitle>{props.title}</S.SlideTitle>
      <S.SlideLink to={props.link}>Dowiedz się więcej</S.SlideLink>
    </S.SlideContainer>
  )
}
