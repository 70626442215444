import React from 'react'
import * as S from '../../styles/components/team/teamStyle'

export default function Slide({ image, title, text, alt }) {
  return (
    <S.SlideContainer>
      <S.SlideImage src={image} alt={alt} />
      <S.SlideTitle>{title}</S.SlideTitle>
      <S.SlideText>{text}</S.SlideText>
    </S.SlideContainer>
  )
}
