import React from 'react'
import * as S from '../../styles/components/list/listStyle'

export default function List(props) {
  return (
    <>
      <S.ListTitle>{props.title}</S.ListTitle>
      <S.UnorderedList>{props.children}</S.UnorderedList>
    </>
  )
}
